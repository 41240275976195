import "./LisdFrame.scss";

function LisdFrame() {
  return (
    <div className="LisdFrame">
      <iframe src="https://app.lisd.io" title="lisd app" />
    </div>
  );
}

export default LisdFrame;
